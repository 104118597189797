#navigation {
  background: #fff;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  max-width: 100%
}

#navigation li {
  padding: 10px
}

#navigation li a {
  color: $rdd-black
}

#navigation li a .fadedNav {
  color: #944545
}

#navigation li a.active {
  text-decoration: underline
}