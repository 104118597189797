body {
  background-color: #ECEBE9 !important
}

* {
  margin: 0;
  padding: 0
}

h1, p {
  line-height: 1.42857143
}

.alert {
  text-align: center;
  margin-bottom: 0
}

h1#title {
  color: $rdd-black;
  padding: 20px 10px;
  margin: 10px 0;
  background: #fff;
  border-bottom: solid 1px $rdd-black;
  border-top: solid 1px $rdd-black;
  font-size: 2em
}

#footerContainer {
  padding: 20px 0;
  align-items: center;
  align-content: center;
  flex-direction: column
}

#footerContainer #footer {
  color: $rdd-black;
  p{
    text-align: center;
    margin-bottom: 0;
    &:last-child{
      font-weight: bold;
    }
  }
  span{
    color: red;
    font-size: 20px;
  }
}

.container {
  position: relative
}

.container img {
  display: block;
  max-width: 100%;
  text-align: center
}

.container .logo {
  margin: 2vh auto;
  height: 105px
}