#rdd {
  align-items: center;
  background: #343a40;
  order: 3;
  padding: 30px;
  color: #fff;
  font-family: HelveticaNeue-Light, "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif
}

@media screen and (min-width: 768px) {
  #rdd {
    order: 3
  }
}

#rdd h2 {
  font-size: 18px;
  text-align: left;
  margin-top: 0
}

#rdd .content {
  display: flex;
  align-items: center;
  flex-direction: column
}

body .container #colContainer #rdd .content p {
  text-align: justify
}

body .container #colContainer #rdd .content p:nth-child(1) {
  font-size: 18px;
  margin-bottom: 10px;
  line-height: 1.1
}

body .container #colContainer #rdd .content img {
  max-width: 250px;
  margin: 2vh 0
}