#tear {
  background-image: linear-gradient(-180deg, #fff 50%, #343a40 50%), linear-gradient(-45deg, #fff 50%, #343a40 50%);
  background-position: top right, top right;
  background-size: 24px 60px;
  background-repeat: repeat-y;
  width: 24px;
  margin: 0;
  padding: 30px 0;
  float: left;
  order: 2
}

@media screen and (max-width: 768px) {
  #tear {
    background-image: linear-gradient(-90deg, #fff 50%, #343a40 50%), linear-gradient(-45deg, #fff 50%, #343a40 50%);
    background-position: top left, top left;
    background-size: 60px 24px;
    background-repeat: repeat-x;
    width: 100%;
    margin: 0;
    padding: 0;
    float: none;
    height: 24px;
    order: 2
  }
}