@media screen and (min-width: 768px) and (max-width: 1023px) {
  #portfolioHolder div.col-md-6 {
    width: calc(100% / 2)
  }

  #portfolioHolder div.col-md-6:first-child {
    padding-left: 0
  }

  #portfolioHolder div.col-md-6:nth-child(2n) {
    padding-right: 0
  }

  #portfolioHolder div.col-md-6:nth-child(3) {
    clear: both;
    padding-left: 0
  }
}

@media screen and (min-width: 1024px) {
  #portfolioHolder div.col-lg-4 {
    width: calc(100% / 3)
  }

  #portfolioHolder div.col-lg-4:first-child {
    padding-left: 0
  }

  #portfolioHolder div.col-lg-4:nth-child(3n) {
    padding-right: 0
  }

  #portfolioHolder div.col-lg-4:nth-child(4) {
    clear: both;
    padding-left: 0
  }
}

#portfolioHolder .panel .panel-body {
  min-height: 52vh
}

#portfolioHolder .panel .panel-body a p {
  min-height: 60px
}

#portfolioHolder .panel .panel-body a img {
  max-width: 100%;
  display: block;
  margin: 0 auto
}

#disclaimer, body .container {
  display: flex;
  flex-direction: column
}

#portfolioHolder .panel .panel-footer button.github i, #portfolioHolder .panel .panel-footer button.readArticle i, #portfolioHolder .panel .panel-footer button.view i {
  text-align: left;
  margin-right: 10px
}

#portfolioHolder .panel .panel-footer {
  min-height: 300px
}

#portfolioHolder .panel .panel-footer button {
  width: 100%;
  text-align: left;
  margin-bottom: 10px
}

#portfolioHolder .panel .panel-footer button.github {
  background: #333;
  color: #fff;
  margin-bottom: 10px
}

#portfolioHolder .panel .panel-footer button.readArticle {
  background: #BB1C2C;
  color: #fff
}

#portfolioHolder .panel .panel-footer button.view {
  background: #FFC128;
  color: #fff
}

#disclaimer {
  align-content: center;
  align-items: center;
  padding: 10px 0 20px
}

#disclaimer button {
  min-width: 92px;
  width: 25%;
  cursor: pointer
}

#disclaimer #disclaimerCopy {
  padding: 20px;
  text-align: justify;
  background: #fff;
  margin: 20px 0
}