#colContainer {
  display: flex;
  flex-direction: column
}

#eigotec {
  background: #fff;
  order: 1;
  padding: 30px;
  color: $rdd-black
}

@media screen and (min-width: 768px) {
  .col-md-6 {
    width: calc(50% - 12px);
    -moz-width: calc(50% - 12px)
  }

  #colContainer {
    flex-direction: row
  }

  #eigotec {
    height: 100%
  }
}

#eigotec .content {
  display: flex;
  align-items: center;
  flex-direction: column
}

#eigotec .content h1 {
  font-size: 24px;
  margin: 0 0 10px;
  padding: 0;
  line-height: 1.42857143
}

h2 {
  font-size: 18px;
  text-align: left;
  margin-top: 0
}

h3 {
  margin-top: 0
}

img#profile {
  width: 100px;
  height: 100px;
  margin: 2vh 0
}

@media screen and (max-width: 768px) {
  body .container #colContainer #eigotec .content img#profile {
    display: none
  }
}

.content p {
  text-align: justify
}

p:nth-child(1) {
  margin-bottom: 20px
}

.content hr {
  margin: 30px 0;
  display: block;
  color: $rdd-black;
  width: 100%
}

.content #subscribe {
  color: $rdd-black;
  background-color: #f5f5f5;
  border-radius: 20px;
  width: 100%
}

.content #subscribe button {
  color: #fff;
}

ul.nav-tabs li a {
  border: 1px solid $rdd-black;
  color: $rdd-black
}

ul.nav-tabs li.active a {
  color: #fff;
  cursor: default;
  background-color: $rdd-black;
  border: 1px solid $rdd-black;
  border-bottom-color: transparent
}

div#accordion {
  width: 100%
}

div#accordion div.panel-body {
  padding: 10px;
  text-align: left
}

div#accordion div.panel-body .label {
  margin-right: 5px
}

div#accordion div.panel-body .label.badge-warning {
  background-color: #f89406
}

div#accordion div.panel-body .label.label-success {
  background-color: #468847
}

div#accordion div.panel-body .label.label-info {
  background-color: #3a87ad
}